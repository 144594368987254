import Vue from 'vue'
import VueAuth from '@websanova/vue-auth'
import router from './../router'

Vue.router = router

Vue.use(VueAuth, {
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  registerData: { url: `${process.env.VUE_APP_BASE_URL}/account/register`, method: 'POST', redirect: '/' },
  loginData: { url: `${process.env.VUE_APP_BASE_URL}/account/login`, method: 'POST', redirect: '/' },
  refreshData: { url: `${process.env.VUE_APP_BASE_URL}/account/user`, method: 'GET', redirect: '/', enabled: false },
  tokenStore: ['cookie', 'localStorage'],
  token: [{ request: 'token', response: 'token', authType: 'Bearer', foundIn: 'response' }],
  fetchData: { url: `${process.env.VUE_APP_BASE_URL}/account/user`, method: 'GET', enabled: true },
  rolesVar: 'roles'
})
