import Vue from 'vue'

// Lib imports
import axios from 'axios'
import VueAxios from 'vue-axios'

let http = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  // baseURL: `https://localhost:44311/api`,
  // baseURL: `https://vending.univercity.su/api`,
  // baseURL: `https://vavilontest-api.univercity.su/api`,
  headers: {
    'Access-Control-Allow-Origin': '*'
    // 'Authorization': result.token
  }
})

// http.post('account/login', {
//   'username': 'kostya@mail2000.ru',
//   'password': '12345678'
// }).then(result => { })

// Vue.prototype.$http = http

Vue.use(VueAxios, http)
