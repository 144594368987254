/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/dashboard',
    // Relative to /src/views
    view: 'Dashboard',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/shopitems',
    name: 'Товары',
    view: 'ShopItems',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    view: 'UserProfile',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/orders',
    name: 'Заказы',
    view: 'TableList',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/orders/:id',
    name: 'Заказ',
    view: 'OrderDetailView',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/clients',
    name: 'Клиенты',
    view: 'ClientsList',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/cards',
    name: 'Карты',
    view: 'CardsList',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/clients/:id',
    name: 'Клиент',
    view: 'ClientView',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/typography',
    view: 'Typography',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/places',
    name: 'Точки продаж',
    view: 'PlacesList',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/places/:id',
    name: 'Точка продажи',
    view: 'PlaceView',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/machines',
    name: 'Торговые автоматы',
    view: 'VendingMachines',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/machines/:id',
    name: 'Торговый автомат',
    view: 'VendingMachineView',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/machines/:id/cells',
    name: 'Ячейки торгового автомата',
    view: 'VendingMachineCellsView',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/machines/:id/loaded',
    name: 'Задание для торгового автомата',
    view: 'VendingMachineLoadItemsView',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/machines/:id/events',
    name: 'Лог торгового автомата',
    view: 'VendingMachineEvents',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/machines/:id/menu',
    name: 'Меню торгового автомата',
    view: 'VendingMachineShopItemsView',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/statistics',
    name: 'Статистика по товарам',
    view: 'EventStatistics',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/device-statistics',
    name: 'Статистика продаж',
    view: 'DeviceStatistics',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/categories',
    name: 'Категории',
    view: 'CategoriesList',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/commands',
    name: 'Команды',
    view: 'CommandsList',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/actions',
    name: 'Действия',
    view: 'CellActionsList',
    meta: { auth: { roles: ['admin', 'manager'], forbiddenRedirect: '/' } }
  },
  {
    path: '/icons',
    view: 'Icons'
  },
  {
    path: '/login',
    name: 'Вход',
    view: 'Login',
    meta: { auth: false }
  }
  // {
  //   path: '/maps',
  //   view: 'Maps'
  // },
  // {
  //   path: '/notifications',
  //   view: 'Notifications'
  // },
  // {
  //   path: '/upgrade',
  //   name: 'Upgrade to PRO',
  //   view: 'Upgrade'
  // }
]
