// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'

// Sync store with router
sync(store, router)

Vue.config.productionTip = false

Vue.filter('date', function (value) {
  if (!value) return ''
  var date = new Date(value)
  var mm = date.getMonth() + 1
  var dd = date.getDate()

  return [(dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    date.getFullYear()
  ].join('.')
})

Vue.filter('time', function (value) {
  if (!value) return 0

  var date = new Date(value)

  var hours = '0' + date.getHours()
  var minutes = '0' + date.getMinutes()
  var seconds = '0' + date.getSeconds()

  var formattedTime = ''
  formattedTime += hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
  return formattedTime
})

Vue.filter('dateutc', function (value) {
  if (!value) return ''
  var dateZ = new Date(value)
  var date = Date.UTC(dateZ.getUTCFullYear(), dateZ.getUTCMonth(), dateZ.getUTCDate(),
    dateZ.getUTCHours(), dateZ.getUTCMinutes(), dateZ.getUTCSeconds())
  var mm = date.getMonth() + 1
  var dd = date.getDate()

  return [(dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    date.getFullYear()
  ].join('.')
})

Vue.filter('timeutc', function (value) {
  if (!value) return 0

  var dateZ = new Date(value)
  var date = Date.UTC(dateZ.getUTCFullYear(), dateZ.getUTCMonth(), dateZ.getUTCDate(),
    dateZ.getUTCHours(), dateZ.getUTCMinutes(), dateZ.getUTCSeconds())

  var hours = '0' + date.getHours()
  var minutes = '0' + date.getMinutes()
  var seconds = '0' + date.getSeconds()

  var formattedTime = ''
  formattedTime += hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
  return formattedTime
})

/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
